  <template>
      <div>
<!--        <b-row class="h-100" v-if="state === 'loading'" align-h="center">-->
<!--          <b-spinner label="Loading..."></b-spinner>-->
<!--        </b-row>-->
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col>
                  <h4>Add Contact</h4>
                </b-col>
              </b-row>
              <b-form v-if="state === 'show'" >
                <b-row>
                  <b-col cols="4">
                    <label>Company <span class="text-red">*</span></label>
                    <b-input-group>
                      <b-form-input disabled v-model="$v.contactValues.company.$model"
                                    @blur="$v.contactValues.$touch()"></b-form-input>
                      <b-input-group-append>
                        <b-button size="sm" text="Button" variant="secondary" @click="openSearchModal">Search</b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="$v.request.company.companyName.$error" class="text-red font-weight-400 text-left">
                      <p v-if="!$v.request.company.companyName.required" >This is a required field</p>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <label>Name <span class="text-red">*</span></label>
                    <b-form-input v-model="$v.contactValues.name.$model"
                                  @blur="$v.contactValues.name.$touch()"></b-form-input>
                    <div v-if="$v.contactValues.name.$error" class="text-red font-weight-400 text-left">
                      <p v-if="!$v.contactValues.name.required" >This is a required field</p>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <label>Surname <span class="text-red">*</span></label>
                    <b-form-input v-model="$v.contactValues.surname.$model"
                                  @blur="$v.contactValues.surname.$touch()"></b-form-input>
                    <div v-if="$v.contactValues.surname.$error" class="text-red font-weight-400 text-left">
                      <p v-if="!$v.contactValues.surname.required" >This is a required field</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">
                    <label>Email <span class="text-red">*</span></label>
                    <b-form-input
                        v-model="$v.contactValues.email.$model"
                        @blur="$v.contactValues.email.$touch()"></b-form-input>
                    <div v-if="$v.contactValues.email.$error" class="text-red font-weight-400 text-left">
                      <p v-if="!$v.contactValues.email.email" >Please enter a valid email</p>
                      <p v-if="!$v.contactValues.email.required" >This is a required field</p>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <label>Landline Number <span class="text-red">*</span></label>
                    <b-form-input type="email" v-model="$v.contactValues.landlineNumber.$model"
                                  @blur="$v.contactValues.landlineNumber.$touch()"></b-form-input>
                    <div v-if="$v.contactValues.landlineNumber.$error" class="text-red font-weight-400 text-left">
                      <p v-if="!$v.contactValues.landlineNumber.minLength || !$v.contactValues.landlineNumber.maxLength || !$v.contactValues.landlineNumber.numeric" >Please enter a valid number</p>
                      <p v-if="!$v.contactValues.landlineNumber.required" >This is a required field</p>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <label>Mobile Number <span class="text-red">*</span></label>
                    <b-form-input v-model="$v.contactValues.cellNumber.$model"
                                  @blur="$v.contactValues.cellNumber.$touch()" ></b-form-input>
                    <div v-if="$v.contactValues.cellNumber.$error" class="text-red font-weight-400 text-left">
                      <p v-if="!$v.contactValues.cellNumber.minLength || !$v.contactValues.cellNumber.maxLength || !$v.contactValues.cellNumber.numeric" >Please enter a valid number</p>
                      <p v-if="!$v.contactValues.cellNumber.required" >This is a required field</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">
                    <label>Location <span class="text-red">*</span></label>
                    <b-form-select v-model="contactValues.location">
                      <b-form-select-option v-for="(item, index) in locations" :key="index" :value="item">{{item.locationName}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <b-col cols="4" v-if="contactValues.location.locationId === 0">
                    <label>Location Name<span class="text-red">*</span></label>
                    <b-form-input v-model="contactValues.newLocation.locationName"></b-form-input>
                    <p v-if="locationFilled === false" >This is a required field</p>
                  </b-col>
                  <b-col cols="4" v-if="contactValues.location.locationId === 0">
                    <label>Address<span class="text-red">*</span></label>
                    <b-form-input v-model="contactValues.newLocation.address"></b-form-input>
                    <p v-if="locationFilled === false" >This is a required field</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" v-if="contactValues.location.locationId === 0">
                    <label>City</label>
                    <b-form-input v-model="contactValues.newLocation.city"></b-form-input>
                  </b-col>
                  <b-col cols="4" v-if="contactValues.location.locationId === 0">
                    <label>Country</label>
                    <b-form-input v-model="contactValues.newLocation.country"></b-form-input>
                  </b-col>
                  <b-col cols="4" v-if="contactValues.location.locationId === 0">
                    <label>Postal Code</label>
                    <b-form-input v-model="contactValues.newLocation.postalCode"></b-form-input>
                  </b-col>
                </b-row>
                <hr class="mx-3">
                <b-row>
                  <b-col>
                    <div class="d-flex justify-content-end">
                      <div>
                        <b-button variant="red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                      </div>
                      <div>
                        <b-button variant="primary" squared @click="locationCreate" class="ml-2" :disabled="$v.contactValues.$invalid">Save</b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
        <b-modal id="searchModal" hide-footer hide-header-close title="Company Search" size="xl">
          <b-row>
            <b-col cols="4">
              <label>Company Name</label>
              <b-form-input v-model="search.name"></b-form-input>
            </b-col>
          </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button variant="red" squared class="mr-2" @click="clearSearch">Clear</b-button>
              <b-button variant="primary" squared @click="companySearch">Search</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-table striped hover
                     :items="tableCompanyData.dataSource"
                     :fields="tableCompanyData.tableColumns"
                     :busy="tableCompanyData.isLoading"
                     :per-page="tableCompanyData.resultsPerPage"
                     id="companySearchModal"
                     @row-clicked="addCompany"
                     :current-page="tableCompanyData.currentPage" sort-icon-left>

              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                </div>
              </template>

              <template #cell(actions)="row">
                <b-row align-v="center" align-h="end">
                  <b-button @click="addCompany(row.item)" size="sm" class="btn-icon">
                    <b-icon-chevron-right></b-icon-chevron-right>
                  </b-button>
                </b-row>
              </template>
            </b-table>
          </b-row>
          <b-row align-h="center" >
            <b-pagination
                v-model="tableCompanyData.currentPage"
                :total-rows="rows"
                :per-page="tableCompanyData.resultsPerPage"
                aria-controls="companySearchModal"
            ></b-pagination>
          </b-row>
          <b-row class="mt-4">
            <b-col class="text-right">
              <b-button variant="red" class="mr-2" squared @click="hideSearchModal">Cancel</b-button>
            </b-col>
          </b-row>
        </b-modal>
    </div>
  </template>

  <script>
  import {mapActions, mapMutations} from "vuex";
  import {required, email, minLength, maxLength, numeric} from "vuelidate/lib/validators";

  export default {
    name: "createContact",
    data: () => ({
      state: 'show',
      contactValues: {
        name: null,
        surname: null,
        email: null,
        cellNumber: null,
        companyId: null,
        company: null,
        landlineNumber: null,
        location: {locationId: null},
        newLocation: {locationName: null, address: null, city: null, country: null, postalCode: null}
      },
      locationValues: {
        companyID: null,
        locationName: null,
        address: null,
        city: null,
        country: null,
        postalCode: null,
      },
      tableCompanyData: {
        resultsPerPage: 4,
        currentPage: 1,
        dataSource: [],
        isLoading: true,
        tableColumns: [
          {
            label: 'Company',
            key: 'description',
            sortable: true,
            tdClass: ''
          },
          {
            label: '',
            key: 'actions',
            sortable: true,
            tdClass: ''
          }
        ],
      },
      search: {
        name: null,
      },
      request: {
        company: {companyName: null},
      },
      locations: [],
      locationFilled: null,
    }),
    created() {
      this.setBreadcrumb([
        {
          text: 'Contact'
        },
        {
          text: 'Add New Contact'
        },
      ])
    },
    methods: {
      ...mapActions(["createContact", "searchCompanies", "searchLocationByCompany", "registerNewLocation"]),
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),
      ...mapActions('notificationService', ['addWarningMessage']),

      addNewContact() {
        let request = {
          name: this.contactValues.name,
          surname: this.contactValues.surname,
          email: this.contactValues.email,
          cellNumber: this.contactValues.cellNumber,
          companyID: this.contactValues.companyId,
          locationId: this.contactValues.location.locationId,
          location: this.contactValues.newLocation.locationName,
          phone: this.contactValues.landlineNumber,
        }
        this.$store.commit('setContactCreateRequest', request)
        this.state = 'loading'
        this.createContact()
            .then(() => {
              this.goBackToSearch()
            })
            .catch(() => {
              this.state = 'show'
            })
      },
      goBackToSearch(){
        this.$router.back()
      },
      openSearchModal() {
        this.$bvModal.show('searchModal')
        this.companySearch()
      },
      hideSearchModal() {
        this.$bvModal.hide('searchModal')
      },
      companySearch() {
        this.tableCompanyData.isLoading = true
        let request = []

        if (this.search.name != null) {
          request.push({'key': 'companyName', 'value': this.search.name})
        }
        this.$store.commit('setSearchCompaniesRequest', request)
        this.searchCompanies()
            .then((response) => {
              this.contactValues.companyId = response.data.companyID
              this.tableCompanyData.dataSource = response.data
              this.tableCompanyData.isLoading = false
            })
            .catch(() => {
              this.tableCompanyData.isLoading = false
            })
      },
      clearSearch() {
        this.search = {
          name: null
        }
        this.companySearch()
      },
      addCompany(rowItem){
        this.contactValues.company = rowItem.description
        this.contactValues.companyId = rowItem.id
        this.$store.commit('setSearchCompaniesRequest', rowItem)
        this.hideSearchModal()
        this.locationSearch(rowItem)
      },
      //TODO clear the data from the drop down before reloading the data for a new company.
      locationSearch(data) {
        let request = {
          companyID: data.id,
        }
        this.$store.commit('setSearchLocationByCompanyRequest', request)

        this.searchLocationByCompany()
            .then((response) => {
              this.locations = response.data
              this.locations.unshift({locationId: 0, locationName: 'Add new location'})
            })
      },
      locationCreate() {
        if (this.contactValues.location.locationId === 0) {
          this.locationValues.companyID = this.contactValues.companyId
          this.locationValues.locationName = this.contactValues.newLocation.locationName
          this.locationValues.address = this.contactValues.newLocation.address
          this.$store.commit('setLocationCreateRequest', this.locationValues)
          this.registerNewLocation()
              .then((res) => {
                this.contactValues.location = {
                  locationId: res.data.result.locationId,
                  locationName: this.contactValues.newLocation.locationName,
                  companyID: this.contactValues.companyId
                }
                this.addNewContact()
              })
              .catch(() => {
              })
        }
        else{
          this.addNewContact()
        }
      },
    },
    computed: {
      rows(){
        return this.tableCompanyData.dataSource.length
      },
    },
    validations: {
      contactValues: {
        name: {required},
        surname: {required},
        company: {required},
        landlineNumber: {required, minLength: minLength(10), maxLength: maxLength(10), numeric},
        cellNumber: {required, minLength: minLength(10), maxLength: maxLength(10), numeric},
        email: { required, email },
      },
      request: {
        company :{
          companyName: {required}
        }
      },
    },
  }

  </script>

<style scoped>

</style>